import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About Us | Discover Serenity Waves Spa & Wellness Resort
			</title>
			<meta name={"description"} content={"Embark on a Journey of Wellness and Discovery"} />
			<meta property={"og:title"} content={"About Us | Discover Serenity Waves Spa & Wellness Resort"} />
			<meta property={"og:description"} content={"Embark on a Journey of Wellness and Discovery"} />
			<meta property={"og:image"} content={"https://movilespin.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://movilespin.com/img/5099647.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://movilespin.com/img/5099647.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://movilespin.com/img/5099647.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://movilespin.com/img/5099647.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://movilespin.com/img/5099647.png"} />
			<meta name={"msapplication-TileImage"} content={"https://movilespin.com/img/5099647.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Content-6">
			<Override
				slot="SectionContent"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				width="100%"
				min-width="100%"
				lg-grid-template-columns="1fr"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				background="rgba(0, 0, 0, 0) url(https://movilespin.com/img/3.jpg) 50% 50% /cover repeat scroll padding-box"
				lg-height="600px"
				md-height="500px"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				padding="80px 60px 80px 60px"
				sm-padding="50px 25px 80px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
				Our Story – A Sanctuary Born from Passion
				</Text>
				<Text margin="0px 0px 25px 0px" font="normal 300 18px/1.2 --fontFamily-sans" color="#505257">
				Serenity Waves Spa & Wellness Resort was conceived from a deep-rooted belief in the transformative power of relaxation and wellness. Our journey began with a vision to create a unique retreat that harmonizes the therapeutic essence of nature with the healing practices of the finest spa traditions. Through years of dedication and passion, we have cultivated an environment where stress dissolves, well-being flourishes, and harmony is restored, making every visit a rejuvenating escape.
				</Text>
				<Text margin="0px 0px 20px 0px" font="normal 500 25px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
				Our Philosophy – Wellness Woven into Every Detail
				</Text>
				<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
				At the heart of Serenity Waves, our philosophy is simple yet profound: to provide an immersive experience that nurtures the soul, rejuvenates the body, and enlightens the mind. We believe in the synergy of holistic wellness practices, integrating ancient wisdom with modern techniques to offer treatments that are personalized and therapeutic. Each element of our resort, from the ambient settings to the handpicked ingredients in our treatments, is chosen with intention and care, ensuring a holistic path to wellness for every guest.
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					What Sets Us Apart – Crafted Experiences and Unmatched Serenity
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Tailored Wellness Journeys
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Every guest's experience is uniquely curated to their wellness needs, ensuring a deeply personal and transformative journey.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Sanctuaries of Solitude
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Our private treatment rooms and secluded spaces offer unparalleled peace and privacy.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Connect with Nature
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Our design and philosophy are deeply rooted in the healing power of the natural world, offering spaces that blend seamlessly with the beauty of our serene landscapes.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="h4"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							At Serenity Waves, we invite you to become part of a story that celebrates wellness, tranquility, and the joy of discovery. Here, every visit is an opportunity to explore new paths to wellness, to connect with a community of like-minded individuals, and to craft your own narrative of rejuvenation and self-discovery. Let us guide you towards a state of complete harmony, where you can truly unwind, recharge, and rediscover your inner peace.
						</Text>
						<Text as="h2" margin="12px 0" font="--headline2" md-font="--headline3">
						Craft Your Path to Wellness – Join Us on a Journey of Discovery and Renewal
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://movilespin.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://movilespin.com/img/5.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://movilespin.com/img/6.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});